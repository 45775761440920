import { Observer } from 'mobx-react';
import { useHeaderSettings } from 'modules/header';
import { HEADER_HEIGHT } from 'modules/header/constants';
import { HeaderVariants } from 'modules/header/types';
import React, { FC, useEffect } from 'react';
import { Switch, withRouter, Route } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';

import { RESOLUTIONS, ROUTER, ROUTER_WITH_LOCALES } from 'src/constants';
import { RaceInfo } from 'src/modules/race';
import { InformationContainer, RegisterRaceContainer } from 'src/modules/race/containers';
import { Results } from 'src/modules/results';
import { Startlist } from 'src/modules/startlist';

import { history } from 'utils';

import { racesService, countriesService } from 'services';

import { racesStore, windowSize, sessionStore } from 'stores';

import Invoice from '../components/invoice/invoice';

import { disposers } from '../stores';

import { observeLanguageChange } from '../reactions';
import { MapContainer } from './MapContainer';

type Props = {
  match?: AnyObject;
  salesMode: boolean; // TODO, remove. I hope
};

const RaceWrapper = styled.div<{ isMobile: boolean; isMargin: boolean }>`
  padding-top: ${(props) => (props.isMobile ? 50 : HEADER_HEIGHT)}px;

  a {
    background: none;
  }

  .scroll-block {
    margin: 0;
    overflow-x: auto;

    ::-webkit-scrollbar {
      display: none;
    }
  }

  @media (max-width: ${RESOLUTIONS.large}px) {
    margin-bottom: ${(props) => (props.isMobile && !props.isMargin ? 100 : 0)}px;
  }
  @media (max-width: ${RESOLUTIONS.small}px) {
    .section-content {
      padding: 0 20px 0;
    }
  }
`;

const HideBodyImage = createGlobalStyle`
  body {
    background-image: none;
  }
`;

const Container: FC<Props> = (props) => {
  const id = props.match!.params.id;

  useHeaderSettings({
    variant: HeaderVariants.normal,
  });

  const initialize = async (id: string) => {
    const response = await racesService.loadResource(id);
    if (response.status === 404) {
      history.replace(ROUTER.NOT_FOUND_PAGE);
    }
  };

  useEffect(() => {
    racesStore.removeSelectedValue();
    countriesService.loadResources();
    initialize(id);
    observeLanguageChange();
    return () => {
      racesStore.removeSelectedValue();
      disposers.disposeAll();
    };
  }, [id]);

  const aboutComponent = () => {
    if (!racesStore.selected) {
      return null;
    }

    return RegisterRaceContainer;
  };

  return (
    <TestAnchorContext.Provider value={{ container: 'RaceDetail' }}>
      <Observer>
        {() => {
          const isAuth = sessionStore.isAuth;
          const isSales = props.salesMode;
          const isMobile = windowSize.isLessThan('medium');

          return (
            <RaceWrapper className='race-container' isMobile={isMobile} isMargin={isAuth}>
              <HideBodyImage />
              <RaceInfo />
              <Switch>
                <Route
                  exact
                  path={isSales ? ROUTER_WITH_LOCALES.RACE_SALES_PAGES_ABOUT : ROUTER_WITH_LOCALES.RACE_ABOUT_US}
                  component={aboutComponent()}
                />
                <Route path={ROUTER_WITH_LOCALES.RACE_INFO} component={InformationContainer} />
                <Route path={isSales ? ROUTER_WITH_LOCALES.RACE_SALES_PAGES_MAP : ROUTER_WITH_LOCALES.RACE_MAP} component={MapContainer} />
                <Route
                  path={isSales ? ROUTER_WITH_LOCALES.RACE_SALES_PAGES_RESULTS : ROUTER_WITH_LOCALES.RACE_RESULT}
                  component={Results}
                />
                <Route
                  path={isSales ? ROUTER_WITH_LOCALES.RACE_SALES_PAGES_STARTLIST : ROUTER_WITH_LOCALES.RACE_START_LIST}
                  component={Startlist}
                />
                <Route path={ROUTER_WITH_LOCALES.RACES_INVOICE} component={Invoice} />
              </Switch>
            </RaceWrapper>
          );
        }}
      </Observer>
    </TestAnchorContext.Provider>
  );
};

export const RaceDetailsContainer = withRouter(Container);
