import { observer } from 'mobx-react';
import React, { Component, ReactNode } from 'react';
import { withRouter } from 'react-router-dom';
// @ts-ignore
import { Match, Location } from 'react-router-dom';
import styled from 'styled-components';

import { LOCALES_STRING } from 'src/constants/general/settings';

import { localeStore } from 'stores';

import { NavItem } from './NavItem';
import { Props } from './NavigationTypes';

const NavList = styled.ul<{ hasActive: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;

  li:first-child {
    padding-left: 0;
  }
`;

const escapeRegExp = (regExpString: string): string => {
  return regExpString.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
};

@observer
@withRouter
export class Navigation extends Component<Props> {
  static defaultProps = {
    intl: null as any,
    size: 'large',
    variant: 'main',
  };

  handleActive =
    (item: AnyObject) =>
    (match: Match, location: Location): boolean => {
      const link = location.pathname.replace(new RegExp(`^\\/(${LOCALES_STRING})`), '') + location.search;

      if (item.exact) {
        return link === item.link;
      }

      const regexp = new RegExp(`^\\${escapeRegExp(item.link)}`);
      return regexp.test(link);
    };

  isActive = (item: AnyObject) => {
    const link = location.pathname.replace(new RegExp(`^\\/(${LOCALES_STRING})`), '') + location.search;
    const regexp = new RegExp(`^\\${escapeRegExp(item.link)}`);
    return regexp.test(link);
  };

  renderListItems() {
    const { navList, closeSideBar, size, variant, withHistoryReplace = false } = this.props;

    return navList.map<ReactNode>((listItem, index) => {
      return (
        <NavItem
          size={size}
          variant={variant}
          onClick={(e) => (closeSideBar ? closeSideBar(listItem.id, e) : '')}
          key={`${listItem.titleLink}-${index}`}
          isActiveFunc={!withHistoryReplace ? this.handleActive(listItem) : null}
          isActive={withHistoryReplace ? this.isActive(listItem) : null}
          withHistoryReplace={withHistoryReplace}
          {...listItem}
        />
      );
    });
  }

  render(): ReactNode {
    const { additionalList, className } = this.props;
    localeStore.value; // Subscribe for locale

    return (
      <NavList className={className} hasActive={false}>
        {this.renderListItems()}
        {additionalList || null}
      </NavList>
    );
  }
}
