import { FE_DATE_FORMAT, FE_TIME_FORMAT, FE_TIME_ZONE_FORMAT, FE_DATE_DAY_MONTH_FORMAT } from 'constants/time';
import { Observer } from 'mobx-react';
import { closeModal } from 'modules/race/actions';
import { distanceInfoOpeningModalStore, distanceInfoStore, raceStore } from 'modules/race/stores';
import moment from 'moment';
import * as React from 'react';
import shortId from 'shortid';
import { isEmpty } from 'validate.js';

import { ShowDesktop, ShowMobile } from 'src/styledComponents/Conditions';
import { Svg } from 'src/styledComponents/Icons';
import { Headline, Utility } from 'src/styledComponents/Typography';

import { Show } from 'components/condition';
import { Modal } from 'components/modal';

import { htmlSanitizer, t, useOnClickOutside } from 'utils';

import { Wrapper, StyledBody, DateTimeSection, PriceSection, EmptyBlock } from './styled';

export const DistanceInfoModal: React.FC = () => {
  const ref = React.useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, () => {
    closeModal();
  });

  return (
    <Observer>
      {() => {
        const isOpen = distanceInfoOpeningModalStore.value;

        if (!distanceInfoStore.value) {
          return null;
        }

        const data = distanceInfoStore.value;

        const startDate = data && moment(data?.registration_starts_at);
        const endDate = data && moment(data?.registration_ends_at);

        const distanceLength = data.race_length >= 1000 ? `${data.race_length / 1000} km` : `${data.race_length} m`;
        const distanceName = data.name;
        const distanceDescription = data.description;
        const isEndDate = Boolean(data.ends_at);

        const raceDate = isEndDate ? `${data.race_date} - ${data.ends_at}` : data.race_date;
        const registrationRangeDate = `${startDate.format(FE_DATE_FORMAT)} - ${endDate.utc().format(FE_DATE_FORMAT)}`;
        const registrationStartTime = `${startDate.format(FE_TIME_FORMAT)} (UTC ${startDate.format(FE_TIME_ZONE_FORMAT)})`;

        const currency = raceStore.value?.currency?.iso_code;

        const priceDate = (price: Price) =>
          `${moment(price.date_from).format(FE_DATE_DAY_MONTH_FORMAT)} - ${moment(price.date_to).format(FE_DATE_FORMAT)}`;

        const removePastPrices = (price: Price) => moment(price.date_to).isSameOrAfter(moment().startOf('day'));

        const actualPrices = !isEmpty(data.prices) && data.prices.filter((price: Price) => removePastPrices(price));

        const data2 = distanceInfoStore.value;

        const maxParticipants = data?.race_qty;
        const raceTime = data?.race_time;

        const headerSection = () => (
          <div className='header-section'>
            <ShowDesktop>
              <Headline className='heading-text' variant='h4'>
                {t.staticAsString('race.ditanceDescription.headingText')}
              </Headline>
            </ShowDesktop>
            <ShowMobile>
              <Headline className='heading-text' variant='h3'>
                {t.staticAsString('race.ditanceDescription.headingText')}
              </Headline>
            </ShowMobile>
            <Svg className='close-button' name='CloseLight' size={24} onClick={closeModal} />
          </div>
        );

        const mainSection = () => (
          <div className='main-section'>
            <ShowDesktop>
              <Utility className='distance-length' variant='u3' weight='bold'>
                {distanceLength}
              </Utility>
            </ShowDesktop>
            <ShowMobile>
              <Utility className='distance-length' variant='u2' weight='bold'>
                {distanceLength}
              </Utility>
            </ShowMobile>
            <Headline className='distance-name' variant='h3'>
              {distanceName}
            </Headline>
            <div
              className='distance-description'
              dangerouslySetInnerHTML={{
                __html: htmlSanitizer(distanceDescription),
              }}
            />
          </div>
        );

        const dateTimeSection = () => (
          <DateTimeSection>
            <div className='column'>
              <Show if={isEndDate}>
                <Utility variant='u4' weight='bold'>
                  {t.staticAsString('race.ditanceDescription.startEndDates')}
                </Utility>
              </Show>
              <Show if={!isEndDate}>
                <Utility variant='u4' weight='bold'>
                  {t.staticAsString('race.ditanceDescription.startDate')}
                </Utility>
              </Show>
              <Utility className='column-value' variant='u4' weight='medium'>
                {raceDate}
              </Utility>
            </div>

            <Show if={raceTime}>
              <div className='column'>
                <Utility variant='u4' weight='bold'>
                  {t.staticAsString('race.distanceDescription.startTime')}
                </Utility>
                <Utility className='column-value' variant='u4' weight='medium'>
                  {raceTime?.substring(0, 5)}
                </Utility>
              </div>
            </Show>

            <div className='column'>
              <Utility variant='u4' weight='bold'>
                {t.staticAsString('race.ditanceDescription.RegistrationStartDates')}
              </Utility>
              <Utility className='column-value' variant='u4' weight='medium'>
                {registrationRangeDate}
              </Utility>
            </div>

            {startDate.isAfter(moment()) ? (
              <div className='column'>
                <Utility variant='u4' weight='bold'>
                  {t.staticAsString('race.ditanceDescription.RegistrationStartTime')}
                </Utility>
                <Utility className='column-value' variant='u4' weight='medium'>
                  {registrationStartTime}
                </Utility>
              </div>
            ) : null}

            {maxParticipants && data.show_startlist ? (
              <div className='column'>
                <Utility variant='u4' weight='bold'>
                  {t.staticAsString('race.distanceDescription.maxParticipants')}
                </Utility>
                <Utility className='column-value' variant='u4' weight='medium'>
                  {maxParticipants}
                </Utility>
              </div>
            ) : null}
          </DateTimeSection>
        );

        const pricesSection = () => {
          const prices = !isEmpty(data.prices) ? (
            actualPrices.map((price: Price) => {
              return (
                <div className='price-column' key={shortId()}>
                  <Headline className='price' variant='h4'>{`${price.value} ${currency}`}</Headline>
                  <Utility className='price-date-range' variant='u4' weight='medium'>
                    {priceDate(price)}
                  </Utility>
                </div>
              );
            })
          ) : (
            <Headline className='price' variant='h4'>
              {t.staticAsString('race.ditanceDescription.free')}
            </Headline>
          );

          return !isEmpty(data.prices) && isEmpty(actualPrices) ? (
            <EmptyBlock />
          ) : (
            <PriceSection>
              <ShowDesktop>
                <Utility className='price-section-heading' weight='bold' variant='u4'>
                  {t.staticAsString('race.ditanceDescription.price')}
                </Utility>
              </ShowDesktop>
              <ShowMobile>
                <Utility className='price-section-heading' weight='bold' variant='u2'>
                  {t.staticAsString('race.ditanceDescription.price')}
                </Utility>
              </ShowMobile>
              <div className='prices'>{prices}</div>
            </PriceSection>
          );
        };

        return (
          <Modal open={isOpen}>
            <Wrapper ref={ref}>
              <StyledBody>
                {headerSection()}
                {mainSection()}
                {dateTimeSection()}
                {pricesSection()}
              </StyledBody>
            </Wrapper>
          </Modal>
        );
      }}
    </Observer>
  );
};
