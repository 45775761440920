import { toJS } from 'mobx';
import { distanceSelector } from 'modules/registration/selectors/mappedData';
import { preloaders } from 'modules/registration/utils';

import { distanceService } from '../../services';

import { distanceStore, isPacePromoBannerEnabledStore, paymentTrackerStore } from '../../stores';

import { formDataSelectors } from '../../selectors/formDataSelectors';

/**
 * @description
 * Load distance into the store based on the option from the dropdown that user selects
 */
async function loadDistance() {
  const distanceId = formDataSelectors.fetchCurrentDistanceId();

  if (!distanceId) {
    return;
  }

  if (distanceId !== distanceSelector.id.get()) {
    preloaders.initializeDistance.enable();
  }

  const distance = await distanceService.load(distanceId, params());
  distance && distanceStore.setValue(distance);
  if (distance && distance.pace_promotion_enabled) {
    isPacePromoBannerEnabledStore.on();
  }

  /**
   * init distance payments,
   * will be used to display pending snack
   */
  const payments = distance?.registrations;
  if (payments?.length) {
    paymentTrackerStore.setPayments(...payments);
  }
}

function params() {
  return {
    with: 'registration_fields;products;custom_fields.values;custom_fields.image;classes;disciplines;firstPrice;lastPrice',
    withCount: 'racers;teams',
  };
}

export { loadDistance };

// Now we using race/raceId and that's why we don't have any payments here (with stripe)
