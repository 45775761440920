import axios from 'axios';
import { API_RACES_INVOICE } from 'constants/api';
import React, { useEffect, useState } from 'react';
import { generatePath } from 'react-router-dom';
import styled from 'styled-components';

import { BodyText, Utility } from 'src/styledComponents/Typography';

import { form, racesStore, windowSize } from 'stores';

import { Show } from '../../../../components/condition';

import { t } from '../../../../utils';

import { Loader } from '../../../../styledComponents/Loader';
import { PageContent } from '../../../../styledComponents/PageContent';
import { FullScreenPulse } from '../../../miles/shared/components';
import { FORM_NEW_INVOICE, InvoiceForm } from './InvoiceForm';
import { InvoiceSuccess } from './InvoiceSuccess';
import { InvoiceTitle } from './InvoiceTitle';
import { PriceSummary } from './PriceSummary';
import { InvoiceDistance } from './invoiceDistance';

const Wrapper = styled.div`
  margin: 65px 0;
`;

const Column = styled.div<{ isMobile: boolean }>`
  width: 100%;
  max-width: 1280px;
  align-items: flex-start;
  display: flex;
  gap: 24px;
  flex-direction: ${(props) => (props.isMobile ? 'column' : 'row')};
`;

const LeftRow = styled.div<{ isMobile: boolean }>`
  flex: 1;
  width: 100%;
  h1 {
    font-size: 2em;
  }
  position: relative;
`;

const RightRow = styled.div<{ isMobile: boolean }>`
  flex: 1;
  width: 100%;
  margin-top: ${(props) => (props.isMobile ? '14px' : '0')};
`;

export type SuccessResponse = {
  invoice: string;
  links: {
    distance_id: number;
    distance_name: string;
    max_usages: number;
    url: string;
  }[];
};

export default function Invoice() {
  const [isLoading, setIsLoading] = useState(false);
  const [successResponse, setSuccessResponse] = useState<SuccessResponse | null>(null);
  const [distanceReservations, setDistanceReservations] = useState<{ [key: number]: number | string }>({});
  const isMobile = windowSize.isLessThan('medium');

  useEffect(() => {
    const distances = racesStore.modelSelectedValue?.value?.distances;
    if (!distances) return;
    const initial = distances?.reduce((acc, distance) => {
      acc[distance.id] = 0;
      return acc;
    }, {});
    setDistanceReservations(initial);
  }, [racesStore.modelSelectedValue?.value]);

  const handleAddDistanceReservation = (distanceId: number, numberOfParticipants: number) => {
    setDistanceReservations((prev) => ({ ...prev, [distanceId]: numberOfParticipants }));
  };

  const handleSubmit = async () => {
    const formData: Record<string, any> = form.fetch(FORM_NEW_INVOICE);

    const registrations = Object.entries(distanceReservations).map(([distanceId, numberOfParticipants]) => ({
      distance_id: Number(distanceId),
      number_of_participants: Number(numberOfParticipants),
    }));

    const payload = {
      ...formData,
      registrations: registrations.filter((r) => r.number_of_participants > 0),
    };
    const raceId = racesStore.modelSelectedValue?.value?.id ?? -1;
    try {
      setIsLoading(true);
      const data = await axios.post<SuccessResponse>(generatePath(API_RACES_INVOICE, { id: raceId }), payload);
      setSuccessResponse(data.data);
    } catch (error) {
      console.log(error);
      setSuccessResponse(null);
    } finally {
      setIsLoading(false);
    }
  };

  const areAllDistancesEmpty = Object.values(distanceReservations).every(
    (value) => value === 0 || value === undefined || value === '' || value === '0',
  );

  return (
    <PageContent>
      <Wrapper>
        <div className='invoice-form-title'>
          <Utility variant='u1' weight={'medium'}>
            {t.staticAsString('invoice.title' as TranslationLockedKeys)}
          </Utility>
        </div>
        <Show if={successResponse === null}>
          <div style={{ marginBottom: '10px' }}>
            <BodyText variant='body2'>{t.staticAsString('invoice.information' as TranslationLockedKeys)}</BodyText>
            <BodyText variant='body2'>{t.staticAsString('invoice.help' as TranslationLockedKeys)}</BodyText>
          </div>
        </Show>

        <Column isMobile={isMobile}>
          <LeftRow isMobile={isMobile}>
            <Show if={isLoading}>
              <FullScreenPulse />
            </Show>
            <Show if={successResponse === null}>
              <InvoiceTitle title={t.staticAsString('invoice.title' as TranslationLockedKeys)} />
              <InvoiceForm areAllDistancesEmpty={areAllDistancesEmpty} handleSubmit={handleSubmit} />
            </Show>
            <Show if={successResponse !== null}>
              <InvoiceSuccess success={successResponse!} />
            </Show>
          </LeftRow>
          <RightRow isMobile={isMobile}>
            <InvoiceTitle title={t.staticAsString('invoice.distanceReservation' as TranslationLockedKeys)} />
            <InvoiceDistance
              handleAddDistanceReservation={handleAddDistanceReservation}
              distanceReservations={distanceReservations}
              successResponse={successResponse}
            />
            <div style={{ height: 20 }}></div>
            <InvoiceTitle title={t.staticAsString('invoice.invoiceSummary' as TranslationLockedKeys)} />

            <PriceSummary distanceReservations={distanceReservations} />
          </RightRow>
        </Column>
      </Wrapper>
    </PageContent>
  );
}
