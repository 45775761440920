import axios from 'axios';
import { flatten, compact } from 'lodash';
import { generatePath } from 'react-router-dom';
import shortid from 'shortid';

import { API_URL, API_GIFTCARD_CHECK } from 'src/constants';

import { request, action } from 'utils';

import { raceStore } from '../stores';

import { controlsSelector } from '../selectors/controls';
import { LGiftcard } from '../types';

class GiftcardService {
  @request({ action: shortid() })
  request(distanceId: number, code: string): any {
    const currency = raceStore.value?.currency?.iso_code;
    const params = {
      currency,
    };

    const url = generatePath(API_URL + API_GIFTCARD_CHECK, { distanceId, code });

    return axios.get(url, { params });
  }

  @action({ action: shortid() })
  async load(distanceId: number, code: string): Promise<LGiftcard | string[]> {
    const [isOk, response] = await this.request(distanceId, code);

    if (!isOk) {
      return compact(flatten([response.data.errors?.coupon_code, response.data.message]));
    }

    return response.data.data;
  }
}

export { GiftcardService };
