import { toJS } from 'mobx';
import { Observer } from 'mobx-react';
import * as React from 'react';

import { TextField } from 'src/styledComponents/TextField';

import { racesStore } from 'stores';

import { t } from '../../../../utils';

import { Distance } from '../../../../models';

import { BodyText } from '../../../../styledComponents/Typography';
import { SuccessResponse } from './invoice';
import './invoice.scss';
import { InvoiceContainer, InvoiceWrapper } from './styles';

const DistanceField = ({
  distance,
  handleAddDistanceReservation,
  distanceReservations,
  successResponse,
}: {
  distance: Distance;
  handleAddDistanceReservation: (distanceId: number, numberOfParticipants: number) => void;
  distanceReservations: { [key: number]: number | string };
  successResponse: SuccessResponse | null;
}) => {
  const handleChange = ({ name, value }: { name: string; value: number }) => {
    handleAddDistanceReservation(distance.value.id, value);
  };

  return (
    <div>
      <TextField
        disabled={distance.isRegistrationClosed() || !!successResponse}
        label={distance.value.name}
        type='number'
        min={0}
        name={`distance_${distance.value.id}`}
        value={distanceReservations[distance.value.id]}
        onChange={handleChange}
      />
      <div style={{ marginLeft: 8, marginTop: 4 }}>
        <BodyText variant='body2'>
          {distance.isRegistrationClosed()
            ? t.staticAsString('registration.messages.registrationClosed')
            : t.staticAsString('invoice.selectAmount' as TranslationLockedKeys)}
        </BodyText>
      </div>
    </div>
  );
};

type Props = {
  handleAddDistanceReservation: (distanceId: number, numberOfParticipants: number) => void;
  distanceReservations: { [key: number]: number | string };
  successResponse: SuccessResponse | null;
};

export const InvoiceDistance = ({ handleAddDistanceReservation, distanceReservations, successResponse }: Props) => {
  return (
    <Observer
      render={() => {
        const racesModel = racesStore.modelSelectedValue;
        const distances = racesModel?.modelDistances();
        return (
          <InvoiceWrapper>
            <InvoiceContainer>
              <div className='invoice-form-container'>
                {(distances || [])
                  .filter((d) => d.value.is_visible)
                  .filter((d) => d.value.currentPrice?.value > 0)
                  .map((d) => (
                    <DistanceField
                      distanceReservations={distanceReservations}
                      handleAddDistanceReservation={handleAddDistanceReservation}
                      distance={d}
                      key={d.value.id}
                      successResponse={successResponse}
                    />
                  ))}
              </div>
            </InvoiceContainer>
          </InvoiceWrapper>
        );
      }}
    />
  );
};
