import styled from 'styled-components';

export const InvoiceWrapper = styled.div`
  background: ${(props) => props.theme.main.colors.white};
  padding: 0;
  text-align: left;
`;

export const InvoiceContainer = styled.div`
  padding: 16px;
`;
