import { Observer } from 'mobx-react';
import React from 'react';

import { t } from 'utils';

import { racesStore } from 'stores';

import './invoice.scss';
import { InvoiceWrapper, InvoiceContainer } from './styles';

type Props = { distanceReservations: Record<number, number | string> };
export function PriceSummary({ distanceReservations }: Props) {
  return (
    <InvoiceWrapper>
      <InvoiceContainer>
        <div>
          <Observer
            render={() => {
              const racesModel = racesStore.modelSelectedValue;
              const distances = racesModel?.modelDistances();
              let totalPrice = 0;
              return (
                <InvoiceWrapper>
                  <InvoiceContainer>
                    <div className='invoice-form-container'>
                      {(distances || [])
                        .filter((d) => d.value.is_visible)
                        .filter((d) => d.value.currentPrice?.value > 0)
                        .map((d) => {
                          const qty = distanceReservations[d.value.id];
                          totalPrice = totalPrice + d.value.currentPrice.value * Number(qty);
                          return (
                            <div className='invoice-form-distance-row' key={d.value.id}>
                              <div>
                                {d.value.name} {d.value.currentPrice.value} {racesModel?.currency()} x {qty}
                              </div>
                              <div>
                                {d.value.currentPrice.value * Number(qty)} {racesModel?.currency()}
                              </div>
                            </div>
                          );
                        })}
                      <div className='invoice-form-distance-row'>
                        <div>{t.staticAsString('profile.segments.total' as TranslationLockedKeys)}</div>
                        <div>
                          {totalPrice} {racesModel?.currency()}
                        </div>
                      </div>
                      <div>{t.staticAsString('invoice.invoice_fee_notice' as TranslationLockedKeys)}</div>
                    </div>
                  </InvoiceContainer>
                </InvoiceWrapper>
              );
            }}
          />
        </div>
      </InvoiceContainer>
    </InvoiceWrapper>
  );
}
