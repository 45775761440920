import { toJS } from 'mobx';
import * as React from 'react';
import styled from 'styled-components';

import { Button } from 'src/styledComponents/Button';
import { Checkbox } from 'src/styledComponents/Checkbox';
import { TextField } from 'src/styledComponents/TextField';

import { Form, InputWrapper, FormContext } from 'components/form';

import { t } from 'utils';

import { form, windowSize, errorsStore } from 'stores';

import './invoice.scss';
import { InvoiceContainer, InvoiceWrapper } from './styles';

export const FORM_NEW_INVOICE = 'FORM_NEW_INVOICE';

interface InvoiceFormData {
  full_name: string;
  email: string;
  company: string;
  reference: string;
  org_number: string;
  address: string;
  city: string;
  post_code: string;
  country: string;
  telephone: string;
}

const REQUIRED_FIELDS = [
  'full_name',
  'email',
  'company',
  'reference',
  'org_number',
  'address',
  'city',
  'post_code',
  'country',
  'telephone',
] as const;

export const InvoiceForm = ({
  handleSubmit,
  areAllDistancesEmpty,
}: {
  handleSubmit: (data: InvoiceFormData) => void;
  areAllDistancesEmpty: boolean;
}) => {
  const isMobile = windowSize.isLessThan('small');
  const [agreement, setAgreement] = React.useState(false);
  const formContext = React.useContext(FormContext);

  const validateForm = () => {
    let isValid = true;
    const formData: InvoiceFormData | undefined = form.fetch(FORM_NEW_INVOICE);

    if (!formData) return false;

    // Clear any existing errors for this form
    errorsStore.clear(FORM_NEW_INVOICE);

    // Collect all validation errors
    const errors: Record<string, string[]> = {};

    REQUIRED_FIELDS.forEach((fieldName) => {
      const value = formData[fieldName];
      if (!value || value.trim() === '') {
        errors[fieldName] = [t.staticAsString('invoice.error.required' as TranslationLockedKeys)];
        isValid = false;
      }
    });

    // Validate email format if email is not empty
    if (formData.email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(formData.email)) {
        errors.email = [t.staticAsString('invoice.error.invalid_email' as TranslationLockedKeys)];
        isValid = false;
      }
    }

    // If there are any errors, add them to the error store
    if (!isValid) {
      errorsStore.add(FORM_NEW_INVOICE, errors);
    }

    return isValid;
  };

  const onFormSubmit = (e?: React.FormEvent) => {
    if (e) {
      e.preventDefault();
    }

    if (validateForm()) {
      const formData: InvoiceFormData = form.fetch(FORM_NEW_INVOICE);
      handleSubmit(formData);
    }
  };

  return (
    <InvoiceWrapper>
      <InvoiceContainer>
        <Form className='invoice-form-container invoice-form-grid' id={FORM_NEW_INVOICE} cleanErrorOnChange={true} onSubmit={onFormSubmit}>
          {REQUIRED_FIELDS.map((fieldName) => (
            <InputWrapper
              key={fieldName}
              name={String(fieldName)}
              settings={{
                label: t.staticAsString(`invoice.${fieldName}` as TranslationLockedKeys),
                placeholder: t.staticAsString(`invoice.${fieldName}` as TranslationLockedKeys),
                requiredMark: true,
              }}
              Component={TextField}
            />
          ))}
        </Form>

        <div className='invoice-toggle'>
          <Checkbox
            id='agreement-checkbox'
            label={t.staticAsString('invoice.invoice_confim_toggle' as TranslationLockedKeys)}
            labelPosition='right'
            name='buttonToggle'
            value={agreement}
            onChange={({ value }) => {
              setAgreement(value);
            }}
          />
        </div>

        <Button onClick={onFormSubmit} disabled={!agreement} style={{ marginTop: 40 }} variant='primary' size='medium' ATN='submit'>
          {t.staticAsString('invoice.registerViaInvoice' as TranslationLockedKeys)}
        </Button>
      </InvoiceContainer>
    </InvoiceWrapper>
  );
};
