import classNames from 'classnames';
import * as React from 'react';
import { use100vh } from 'react-div-100vh';
import styled from 'styled-components';

import { RESOLUTIONS } from 'src/constants';
import { ShowDesktop, ShowMobile } from 'src/styledComponents/Conditions';

import { Context } from './Context';

type Props = {
  children: React.ReactNode;
  open: boolean;
  className?: string;
  onClose?: AnyFunction;
  withCloseButton?: boolean;
};

const Wrapper = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;

  background: ${(props) => props.theme.main.colors.clay3}A9;

  @media (min-width: ${RESOLUTIONS.medium}px) {
    align-items: center;
  }
`;

// Needed for big modals on mobile devices
const ScrollableArea = styled.div<{ viewHeight: number }>`
  overflow-y: auto;
  max-height: ${(props) => props.viewHeight}px;
`;

function ModalComponent({ children, open, className, onClose, withCloseButton = false }: Props) {
  const height = use100vh() || 0;

  if (!open) {
    return null;
  }

  const context = {
    withCloseButton,
    onClose,
  };

  // @ts-ignore
  ModalComponent.handleClickOutside = () => onClose && onClose();

  return (
    <Context.Provider value={context}>
      <Wrapper className={classNames('global-modal', className)}>
        <ShowMobile>
          <ScrollableArea viewHeight={height}>{children}</ScrollableArea>
        </ShowMobile>
        <ShowDesktop>{children}</ShowDesktop>
      </Wrapper>
    </Context.Provider>
  );
}

export { ModalComponent as Modal };
