import { loadSquads } from 'modules/registration/actions/loader/loadSquads';

import { countriesService } from 'services';

import { paymentConfigService } from '../../services';

import { currentPaymentStore, isPacePromoBannerEnabledStore, raceStore } from '../..//stores';
import { loadConfirmation } from './loadConfirmation';
import { loadDistancesList } from './loadDistancesList';
import { loadRace } from './loadRace';
import { loadRacerRegistration } from './loadRacerRegistration';
import { loadTransferring } from './loadTransferring';
import { loadWaves } from './loadWaves';

/**
 * @description
 * load basic data needed for the registration
 * load race, distance, and distances list for the dropdown, and registration ttl
 */
async function loadBaseData() {
  const promises = [
    paymentConfigService.loadRegistrationTTL(),
    loadRace({ setToStore: false }),
    loadConfirmation(),
    loadRacerRegistration(),
    loadTransferring(),
    loadDistancesList(),
    loadWaves(),
    countriesService.loadResources(),
    loadSquads(),
  ];

  const [registrationTTL, race] = await Promise.all<any>(promises);

  if (race) {
    raceStore.setValue(race);
  }

  currentPaymentStore.setRegistrationTTL(registrationTTL);
}

export { loadBaseData };
