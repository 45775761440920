import * as React from 'react';
import styled from 'styled-components';

import { PrimaryButton } from 'src/styledComponents/Button';
import { SecondaryButton } from 'src/styledComponents/Button/Secondary';

import { t } from 'utils';

import { BodyText, Utility } from '../../../../styledComponents/Typography';
import { InvoiceTitle } from './InvoiceTitle';
import { SuccessResponse } from './invoice';
import { InvoiceContainer, InvoiceWrapper } from './styles';

const LinkContainer = styled.div`
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

const LinkWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const AccessButton = styled(Utility)`
  padding: 8px 16px;
  border-radius: 4px;
  background: rgba(0, 100, 255, 0.1);
  border: 1px solid rgba(0, 100, 255, 0.3);
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: rgba(0, 100, 255, 0.15);
  }
`;

const TextWrapper = styled.div`
  margin-bottom: 28px;
`;

const LinkLabelWrapper = styled.div`
  margin-bottom: 14px;
`;

const CopyButton = styled.button`
  padding: 4px 8px;
  background: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background: #e0e0e0;
  }

  &:active {
    background: #d0d0d0;
    transform: scale(0.98);
  }
`;
const CommonButton = styled(PrimaryButton)`
  height: 60px;
`;

const CommonSecondaryButton = styled(SecondaryButton)`
  height: 60px;
`;

export const InvoiceSuccess = ({ success }: { success: SuccessResponse }) => {
  const handleCopy = async (linkUrl: string) => {
    try {
      await navigator.clipboard.writeText(linkUrl);
    } catch (err) {
      console.error('copy faile', err);
    }
  };

  return (
    <InvoiceWrapper>
      <InvoiceTitle title={t.staticAsString('invoice.success_title' as TranslationLockedKeys)} />
      <InvoiceContainer>
        <div>
          <TextWrapper>
            <BodyText variant='body2'>
              {t.staticAsString('invoice.success_description' as TranslationLockedKeys, { invoice: success.invoice })}
            </BodyText>
          </TextWrapper>

          {success?.links.map((link) => (
            <LinkContainer key={link.distance_id}>
              <LinkLabelWrapper>
                <Utility variant='u4' weight='bold'>
                  {link.distance_name}
                </Utility>
              </LinkLabelWrapper>

              <LinkWrapper>
                {/* <AccessButton variant='u4' weight='bold' onClick={() => window.open(link.url, '_blank')}>
                  Access Resource
                </AccessButton> */}
                <CommonButton onClick={() => window.open(link.url, '_blank')} size={'small'}>
                  {t.staticAsString('invoice.accessLinks' as TranslationLockedKeys)}
                </CommonButton>
                <CommonSecondaryButton onClick={() => handleCopy(link.url)} size={'small'}>
                  {t.staticAsString('invoice.copyLinks' as TranslationLockedKeys)}
                </CommonSecondaryButton>

                <BodyText variant='body2'>
                  ({t.staticAsString('invoice.usagesOnLink' as TranslationLockedKeys)} {link.max_usages})
                </BodyText>
                {/* <BodyText variant='editorial1'>{link.url}</BodyText> */}
              </LinkWrapper>
            </LinkContainer>
          ))}
          <div>
            <BodyText variant='body2'>{t.staticAsString('invoice.help' as TranslationLockedKeys)}</BodyText>
          </div>
        </div>
      </InvoiceContainer>
    </InvoiceWrapper>
  );
};
