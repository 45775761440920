// root api url for web
export const API_URL = '/api/v1/web';
export const API_V2_URL = '/api/v2/web';
export const API_MILES_URL = '/api/v1/miles';

//auth
export const API_LOGIN_URL = '/user/login';
export const API_FB_LOGIN_URL = '/user/fb-auth';
export const API_APPLE_LOGIN_URL = '/auth/apple';
export const API_GOOGLE_LOGIN_URL = '/auth/google';
export const API_LOGOUT_URL = '/user/login';
export const API_REGISTRATION_URL = '/user/register';
export const API_FORGOT_URL = '/user/password/forgot';
export const API_UPDATE_PASSWORD_URL = '/user/passwords';
export const API_RESSET_PASSWORD = '/user/password/reset';
export const API_CHECK_PASSWORD = '/user/reset-password';
export const API_REFRESH_TOKEN = '/user/tokens';
export const ADMIN_API_LOGIN_URL = `/api/v1/admin/tokens`;

//User
export const API_USER_PROFILE_URL = '/user/profile';
export const API_USER_CHECK_EMAIL_URL = '/user/profile/exists';
export const API_USER_AVATAR_URL = '/user/avatar';
export const API_RACE_FAVORITE_URL = '/user/wishlist';
export const API_WISHLIST = '/wishlist';

export const API_UNIONS = '/unions';
export const API_USER_RESULTS = '/users/:id/results';
export const API_VIRTUAL_RESULTS_FUTURE = '/user/virtual-distances-results/future';
export const API_VIRTUAL_RESULTS_PAST = '/user/virtual-distances-results/past';

export const API_VIRTUAL_RESULT = '/user/virtual-distance-result/:id';
export const API_VIRTUAL_RESULTS = '/user/virtual-distances-results';

export const API_CLASSIC_RESULTS = '/user/classic-distances-results';
export const API_CLASSIC_RESULTS_FUTURE = '/user/classic-distances-results/future';
export const API_CLASSIC_RESULTS_PAST = '/user/classic-distances-results/past';

export const API_CUMULATIVE_RESULTS = '/user/cumulative-distances-results';

export const API_SEND_RESULT = '/racers/:id/results';

export const API_DISTANCE_BY_ID = '/distances/:id';

// User registered to
export const API_USER_REGISTERED_RACES = '/user/races';
export const API_USER_REGISTERED_DISTANCES = '/users/:id/distances';

// Profile export
export const API_EXPORT_DIPLOMA_URL = '/exports/finisher-certificate/:racerId';
export const API_EXPORT_BIB_URL = '/exports/bib/:racerId';
// Countries
export const API_COUNTRIES_URL = '/countries';
export const API_DISTANCES_URL = '/distances-count';
// Spot Types
export const API_SPORT_TYPES_URL = '/sports';
//Articles
export const API_ARTICLES_URL = '/articles';
//Races
export const API_RACES_URL = '/races';
export const API_RACES_INVOICE = API_URL + '/races/:id/invoice-registrations';
//LOCATIONS
export const API_LOCATIONS_URL = '/locations';
//Payments
// export const API_PAYMENTS_URL = '/payment/methods'; NOTE DISABLE PAYMENT METHOD
export const API_PAYMENT = '/payment/:token';

//MAP
export const GOOGLE_MAP_URL = {
  development: 'AIzaSyBwfe6c1Yv0pr1zvjTloWLOJgLN8egajpU',
  staging: 'LUvn_MG70YReJTRt39jpUetIZiPQ',
  production: 'AIzaSyCoxbZA2v5UdOHXvDuH8IyuVqc0SH8BSvQ',
};

//DISTANCES
export const API_DISTANCES = '/races/:id/distances';
export const API_DISTANCE = '/races/:raceId/distances/:distanceId';
export const API_DISTANCE_V2 = '/distances/:id';

//Racers
export const API_RACER = '/racers/:id';
export const API_DISTANCE_RACERS = '/distances/:id/racers';
export const API_DISTANCE_RESULTS = '/distances/:id/results';
export const API_DISTANCE_TEAMS = '/distances/:id/teams';
export const API_DISTANCE_LAST_FINISHED_CHECKPOINT = '/distances/:id/last-finished-checkpoint';
export const API_ALL_RACERS = '/races/:id/racers';
export const API_ALL_RESULTS = '/races/:id/results';
export const API_RACERS_COUNTER = '/races/:id/participants-count';
export const API_RACE_RESULTS_COUNTER = '/races/:id/results-count';

//Registration
export const API_REGISTRATION = '/races/:raceId/registrations';
export const API_INVITE = '/invites/:token';
export const API_TRANSFERRED_REGISTRATION = '/transfers';
export const API_CONFIRM_TRANSFERRING = '/races/:raceId/transfers';
export const API_CONFIRMATION = '/races/:raceId/confirmations';
export const API_CHECK_TEAM_NAME = '/distances/:distanceId/team/exists';
export const API_DELETE_CANCELED_PAYMENT = '/payments/:token';
export const API_REGISTRATION_CONFIG = '/registrations/config';
export const API_REGISTRATION_STATUS = '/registrations/:uuid';
export const API_REGISTRATION_RACERS = '/registrations/:registrationId/racers';
export const API_GROUP_MEMBERS = '/distances/:distanceId/members';
export const API_SMART_LINK = '/magic-links/:token';
export const API_GIFTCARD_CHECK = '/giftcards/:code';

//Coupons
export const API_COUPON_API = '/distances/:distanceId/coupon';

//Manage Team
export const API_MANAGE_TEAM_INFO = '/user/races';
export const API_PATCH_TEAM_NAME = '/teams/:teamId';
export const API_ADD_TEAM_MEMBER = '/teams/:teamId/racers';
export const API_EDIT_TEAM_MEMBER = '/teams/:teamId/racers/:racerId';
export const API_RESEND_INVITATION = '/teams/:teamId/racers/:racerId/invite';

//Confirm Profile
export const API_GET_USER_BY_TOKEN = '/xtri/member';
export const API_CONFIRM_PROFILE = '/xtri/activate';
//Confirm Profile Otillo
export const API_GET_OTILLO_USER_BY_TOKEN = '/otillo/member';
export const API_OTILLO_CONFIRM_PROFILE = '/otillo/activate';

export const API_GET_TOUGH_VIKING_USER_BY_TOKEN = '/tough-viking/member';
export const API_ACTIVATE_VIKING_USER_BY_TOKEN = '/tough-viking/activate';

// ATTENTION, HACK, WARNING, you're been warn!
// endpoint below should be typed normally - '/user/xtri/register',
// but because of strange magic of the browser/js/axios level it is not working
// it is sening encoded to the backend
// but for some reason encoded url is working fine

// Xtri registration
export const API_XTRI_REGISTER = '/user/xtri/register';
export const API_OTILLO_REGISTER = '/user/otillo/register ';

// Upload files
export const API_UPLOAD_FILE = '/files';

export const API_AUTHORIZE_RACE = '/races/:id/secret';
export const API_HEALTH_CHECK = '/api/health';

// Segments
export const API_SEGMENTS = '/racers/:id/segments';
export const API_SEGMENT = '/racers/:racerId/segments/:id/';

// export const API_TRANSLATIONS = '/translation/index';
export const API_TRANSLATIONS = `https://d1cckfr8cgrocc.cloudfront.net/translations/web/`;
export const TRANSLATIONS_URL = {
  development: 'https://d1cckfr8cgrocc.cloudfront.net/translations/web/',
  production: 'https://d3q0esj9j6r0nh.cloudfront.net/translations/web/',
  staging: 'https://d1mloj4u9jso1k.cloudfront.net/translations/web/',
};
export const API_EXPORT_JOB_ID = '/jobs/:id';
export const API_EXPORT_REGISTERED_TO = '/exports/receipts/:id';
export const API_EXPORT_PAYMENT_TRANSACTIONS = '/exports/payment-transactions';

// Search
export const API_SEARCH_WITH_LOCATIONS = '/search-location';
export const API_SEARCH_ROUTE = '/search-route';
export const API_SEARCH = '/search';

export const API_DISTANCE_WAVES = '/distances/:id/waves';

// Dynamic link
export const API_DYNAMIC_RACE_LINK = `${API_URL}/races/:id/dynamic-links`;
export const API_DYNAMIC_LINK = `${API_URL}/dynamic-links`;

export const API_DISTANCES_SQUADS = '/distances/:id/squads';

export const API_PLACES = '/places';
export const DISCOVER_RACES = '/discover-races';
export const TRANSFER_REGISTRATION_END_POINT = '/racers/:racerId/transfer';

// MILES
export const MILES_ELLIDA_PARAMS = API_MILES_URL + '/ellida-params';
export const MILES_COMMON_PARAMS = API_MILES_URL + '/common-params';
export const USER_PARAMS = API_MILES_URL + '/user-params';

export const MILES_VALIDATE_COUPON = `${API_URL}/users/:id/promotion-codes`;
export const MILES_VALIDATE_PREPAID = `${API_URL}/users/:id/subscriptions/prepaid`;

export const MILES_TRAINING_PLAN = API_MILES_URL + '/training-plan';
export const MILES_DEVICE_AUTH = API_MILES_URL + '/gadgets/auth';
export const MILES_DEVICE_DEAUTH = API_MILES_URL + '/gadgets/deauth';
export const MILES_CONNECTED_DEVICES = API_MILES_URL + '/gadgets/connected-list';
export const MILES_READINESS_EXPORT = API_MILES_URL + '/readiness/export';
export const MILES_READINESS_RATING = API_MILES_URL + '/readiness/rating';
export const MILES_DAILY_DATA_STREAK = API_MILES_URL + '/daily-data-streak';
export const MILES_SUGGESTED_RACE_URL = '/users/:userId/suggested-race-for-miles';
export const MILES_INSIGHTS = API_MILES_URL + '/insights';
export const MILES_DAILY_RESULTS = API_MILES_URL + '/daily-results/:date';
export const MILES_DATA_QUALITY = API_MILES_URL + '/data-quality';
export const HEART_RATE_ZONES = API_MILES_URL + '/heart-rate-zones';

export const MILES_CREATE_SUBSCRIPTION = API_URL + '/users/:userId/subscriptions';
export const MILES_DELETE_SUBSCRIPTION = API_URL + '/users/:userId/subscriptions/:subscriptionId/cancel';
export const PAST_SESSION = API_MILES_URL + '/past-sessions';

export const MILES_NEWS = API_MILES_URL + '/news';

export const CAROUSEL = API_URL + '/carousel';

export const CAMPAIGN_LINK = API_V2_URL + '/campaign-links/:token';
